export const toClassName = (...values: (boolean | string | undefined)[]) => {
  return values.filter(Boolean).join(' ');
};

export const handleGenerateInitialsFromName = (name: string) => {
  let result = name;

  if (name) {
    result = name.charAt(0);
  }

  return result;
};

export const handleFormatDate = (date: string, t: any) => {
  const dateObject = new Date(date);
  return `${dateObject.getDate()} ${t(`label.date.month.${dateObject.getMonth()}`)} '${dateObject.getFullYear().toString().slice(-2)}`;
};

export const handleFormatCurrency = (value: number) => {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  }).format(value / 100);
};
