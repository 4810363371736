'use client';

import React, { useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';

import { toClassName } from 'helpers';

import { useTranslation } from 'hooks/useTranslation';

import Container from 'components/custom/Container';
import Icon from 'components/custom/Icon';
import LangPicker from 'components/custom/LangPicker';
import LocationPicker from 'components/custom/LocationPicker';

import { styles } from '.';

type TProps = {
  reportEmailAddress: string;
};

const NavBar = (props: TProps) => {
  const { reportEmailAddress } = props;

  const params = useParams();
  const pathname = usePathname();

  const { t } = useTranslation(params?.lang as string);

  const [isOpen, setIsOpen] = useState(true);

  const routes = [
    {
      id: 'home',
      label: t('component.nav.label.home'),
      href: `/${params?.lang}/${params?.slug}/#hero`,
    },
    {
      id: 'practical',
      label: t('component.nav.label.practical'),
      href: `/${params?.lang}/${params?.slug}/#practical`,
    },
    (pathname.includes('/brussels') ||
      pathname.includes('/ghent') ||
      pathname.includes('/ostend')) && {
      id: 'program',
      label: t('component.nav.label.program'),
      href: `/${params?.lang}/${params?.slug}/#program`,
    },
    {
      id: 'pictures',
      label: t('component.nav.label.pictures'),
      href: `/${params?.lang}/${params?.slug}/#pictures`,
    },
    {
      id: 'menu',
      label: t('component.nav.label.menu'),
      href: `/${params?.lang}/${params?.slug}/menu`,
    },
    {
      id: 'jobs',
      label: t('component.nav.label.jobs'),
      href: `/${params?.lang}/${params?.slug}/jobs`,
    },
  ].filter(Boolean);

  return (
    <div className="fixed left-0 top-0 right-0 z-10">
      <div className={styles.container}>
        <Container type="center">
          <div className={styles.content}>
            <div className={styles.head.container}>
              {
                <Link
                  // href={
                  //   pathname === `/${params?.lang}`
                  //     ? `/`
                  //     : `/${params?.lang}/${params?.slug}/#hero`
                  // }
                  href="/"
                  className="cursor-pointer"
                >
                  <Image
                    src="/images/logo-small.svg"
                    width={35}
                    height={35}
                    alt="logo-small"
                    className={styles.figure.small}
                  />
                  <Image
                    src="/images/logo-big.svg"
                    width={300}
                    height={100}
                    alt="logo-small"
                    className={styles.figure.big}
                  />
                </Link>
              }
              {pathname !== `/${params?.lang}` && <LocationPicker />}
            </div>

            <div className={styles.foot.container}>
              <ul className={styles.foot.list}>
                {pathname !== `/${params?.lang}` &&
                  !pathname.includes('/jobs') &&
                  routes.map((route: any) => (
                    <li
                      key={route.id}
                      className={toClassName(
                        !route.href.includes('/menu') &&
                          !route.href.includes('/jobs') &&
                          'hidden sm:block',
                      )}
                    >
                      <Link
                        href={route.href}
                        // href={
                        //   route.href.includes('/jobs') ||
                        //   route.href.includes('/menu')
                        //     ? route.href.replace(
                        //         '[slug]',
                        //         params?.slug as string,
                        //       )
                        //     : route.href.includes('#practical')
                        //       ? route.href.replace(
                        //           '[slug]',
                        //           params?.slug as string,
                        //         )
                        //       : route.href
                        // }
                        className={toClassName(
                          styles.button,
                          '!text-white hover:!bg-white',
                        )}
                      >
                        {route.label}
                      </Link>
                    </li>
                  ))}
                {/* <li>
                  <Link
                    href="/"
                    className={toClassName(
                      styles.button,
                      'px-3 py-2 rounded-sm ring-1 ring-primary-500',
                    )}
                  >
                    {t('component.nav.label.reservation')}
                  </Link>
                </li> */}
                <li className="border-l pl-6 border-gray-850">
                  <LangPicker />
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      {pathname !== `/${params?.lang}` && isOpen && (
        <div className={styles.alert.container}>
          <Container type="center">
            <div className="flex items-center justify-between">
              <p
                className={styles.alert.paragraph}
                dangerouslySetInnerHTML={{
                  __html: t('component.nav.paragraph.alert').replace(
                    '<a>',
                    `<a class="underline hover:text-white" href="mailto:${reportEmailAddress}" target="_blank">`,
                  ),
                }}
              />
              <div onClick={() => setIsOpen(false)}>
                <Icon
                  accessor="xmark"
                  className="cursor-pointer hover:text-white"
                  size="12px"
                />
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default NavBar;
